/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Skeleton, Button } from 'antd';
import axios from 'axios';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css'; // Keen Slider styles
import './TopPosition.css';

function Testimonial({ className }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Mock data for top comments
  const mockData = [
    {
      id: 5,
      title: 'Kiarash',
      field: 'Robotics Engineering',
      image: 'https://via.placeholder.com/150',
      university: 'Carnegie Mellon University',
      date: '2024-11-20',
      description:
        'Join the robotics research team and teach courses in automation and AI integration.',
      feedback: `When it comes to the number and quality of positions, I stumbled upon some opportunities that I never thought I'd find. I was completely unaware of their existence. I felt somewhat discouraged about applying at first, but the existence of Kite made me hopeful that there are relevant positions out there for me, and I can apply for them. If it weren't for Kite, I don't think I could have ever found these positions. Despite being relatively active on networks like LinkedIn, etc., and trying to connect with people to stay updated on their posts.`,
    },
    {
      id: 1,
      title: 'Negin',
      field: 'Artificial Intelligence',
      image: 'https://via.placeholder.com/150',
      university: 'University of Cambridge',
      date: '2024-11-25',
      description:
        'Join cutting-edge AI research to push the boundaries of machine learning technologies.',
      feedback: `Because my field is interdisciplinary, covering urban planning and geography, I found numerous positions and made full use of all available resources and extensions. The list of professors was incredibly helpful. It not only provided access to their research interests and related articles but also highlighted the grants they had received. At this stage, I did use emails for professors who had received grants. Although some adjustments were needed in the email content, which is understandable, overall, it offered valuable ideas for the initial email communication.`,
    },
    {
      id: 2,
      title: 'Amir',
      field: 'Quantum Information',
      image: 'https://via.placeholder.com/150',
      university: 'MIT',
      date: '2024-12-15',
      description:
        'Explore quantum algorithms and computing paradigms with top researchers in the field.',
      feedback: `The platform you designed really saves a lot of time and effort for me. Previously, to find a position, I would first check university programs and deadlines, then review the list of professors, and finally manually browse individual websites to see if they were looking for students or had relevant work. One interesting feature of Kite is its ability to search using keywords, and another is that it automatically alerts related positions daily.`,
    },
    {
      id: 3,
      title: 'Morteza',
      field: 'Network Security',
      image: 'https://via.placeholder.com/150',
      university: 'Stanford University',
      date: '2024-12-30',
      description:
        'Research new cybersecurity methods and protocols for next-gen networks.',
      feedback: `The most significant advantage of this platform is time-saving. Instead of daily scouring through countless websites for positions, often encountering repetitive listings, which can be overwhelming, this bot simplifies the process with just a few clicks. It's excellent! Creating the email text was really cool too.`,
    },
    {
      id: 4,
      title: 'Faezeh',
      field: 'Data Science',
      image: 'https://via.placeholder.com/150',
      university: 'Harvard University',
      date: '2024-12-05',
      description:
        'Support advanced data analytics projects and contribute to impactful research initiatives.',
      feedback: `To be honest, my solution was to search for lists of professors on university websites and then look through the websites of relevant professors. But this was very time-consuming, and there was always the risk of missing out on a position. Your platform could be really helpful in this regard. Plus, I've now discovered some countries that have more positions related to my field.`,
    },
    {
      id: 6,
      title: 'Rajabi',
      field: 'Environmental Science',
      image: 'https://via.placeholder.com/150',
      university: 'University of California, Berkeley',
      date: '2024-12-10',
      description:
        'Teach and engage in research activities in the field of environmental policy and science.',
      feedback: `The platform's selection of positions within my field was quite satisfactory. I found the star ratings particularly helpful in quickly identifying the most promising opportunities. It significantly saved me time compared to my previous method of manually searching university websites. The feature allowing quick review of professors' LinkedIn profiles was a game-changer for me, as I didn't have the time to do this individually. I also appreciated the email templates generated based on my resume and the position requirements. They made reaching out to professors much more straightforward and efficient. Overall, the platform's organization and tailored features aligned perfectly with my job search needs.`,
    },
  ];

  useEffect(() => {
    const fetchPositions = async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setComments(mockData); // Replace with actual API data
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
      setLoading(false);
    };

    fetchPositions();
  }, []);

  // Keen Slider configuration
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      breakpoints: {
        '(max-width: 1024px)': {
          slides: { perView: 1, spacing: 10 },
        },
        '(max-width: 768px)': {
          slides: { perView: 1, spacing: 5 },
        },
      },
      slides: {
        perView: 1,
        spacing: 15,
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 4000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });

        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    <section
      className={`appie-traffic-area pt-140 mt-100 pb-180 ${className || ''}`}
      style={{ background: '#eef1f6' }}
    >
      <div className="container d-md-flex align-items-center">
        <div className="row col-md-12">
          <div className="col-md-12">
            <div className="appie-traffic-title" style={{ paddingRight: 20 }}>
              <h2 className="text-center">
                What Our <span className="clients">Clients</span> Say
              </h2>
            </div>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="slider-container">
                <div ref={sliderRef} className="keen-slider">
                  {comments.map((comment) => (
                    <div key={comment.id} className="keen-slider__slide pt-10">
                      <Card hoverable className="testimonial-card">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="testimonial-card-content">
                          <h3 className="testimonial-title">{comment.title}</h3>
                          {/* <p className="testimonial-field">{comment.field}</p> */}
                          <p className="testimonial-description">
                            {comment.feedback}
                          </p>
                          <div className="testimonial-divider"></div>
                          {/* <p className="testimonial-university">
                            {comment.university}
                          </p> */}
                          <div>
                            <ul className="testimonial-stars">
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                              <li>
                                <i className="fas fa-star" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
