import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FaqHomeOne from './FaqHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import PricingHomeOne from './PricingHomeOne';
import ProjectHomeOne from './ProjectHomeOne';
// TODO: uncomment BlogHomeOne
import BlogHomeOne from './BlogHomeOne';
import WebappOne from './WebappOne';
import WebappSecond from './WebappSecond';
import WebappThird from './WebappThird';
import WebappForth from './WebappForth';
import TutorialSection from './TutorialSection';
// import TopPositions from './TopPositions';
// import TopSupervisors from './TopSupervisors';
import Testimonial from './Testimonial';
// import FunFactHomeThree from '../HomeThree/FunFactHomeThree';

function HomeOne() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} />
      <HeroHomeOne />
      <WebappOne />
      <WebappForth />
      <WebappSecond />
      <WebappThird />
      {/* <TeamHomeOne /> */}
      <PricingHomeOne />
      {/* <FunFactHomeThree /> */}
      <Testimonial />
      <TutorialSection />

      <FaqHomeOne />
      <BlogHomeOne />
      <ProjectHomeOne />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default HomeOne;
