import React, { useEffect, useState } from 'react';
import axios from 'axios';
import addCommas from '../../lib/addCommas';

const apiUrl = process.env.REACT_APP_API_URL;

const UserCounter = () => {
  const [userCount, setUserCount] = useState(null);
  const [newUsers, setNewUsers] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserCount = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/users/counter`);
      setUserCount(response.data[0]);
      setNewUsers(response.data[1]);
    } catch (error) {
      console.error('Error fetching user count:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCount();
  }, []);

  return (
    <div className="title-counter lilita-one-regular">
      Another <span>{loading ? '--' : addCommas(newUsers) ?? '--'}</span> users
      joined this month. <br /> Be part of our{' '}
      <span className="text-purple">
        {loading ? '--' : addCommas(userCount) ?? '--'}
      </span>{' '}
      users using Kite as their assistant!
    </div>
  );
};

export default UserCounter;
