/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import blogImgPlaceholder from '../../../assets/images/blog/1.jpg';
import { formattedMonthDate } from '../../../lib/formattedDate';
import './blogCard.css';

const BlogCard = ({ blog }) => {
  const { title, media_url, publisher_name, created_at } = blog;
  const createdAt = new Date(created_at).toLocaleDateString();

  return (
    <Link to={`/blogs/${blog.slug}`} style={{ display: 'block' }}>
      <Card
        style={{
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.05)',
        }}
        styles={{
          body: { padding: '15px 0' },
        }}
        cover={
          <img
            height={240}
            width="100%"
            alt={title}
            src={media_url || blogImgPlaceholder}
            style={{ objectFit: 'cover' }}
            loading="lazy"
          />
        }
      >
        <h4 className=" blog-title px-4 pt-1">{title}</h4>
        <div className="d-flex justify-content-between blog-footer px-4 pt-3">
          <p className="">By {publisher_name}</p>

          <div style={{ fontSize: 14 }}>
            <i className="fal fa-clock" style={{ color: '#358398' }}></i>{' '}
            {formattedMonthDate(createdAt) ?? ''}
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default BlogCard;
