/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Card, Skeleton, Button, ConfigProvider } from 'antd';
import { useKeenSlider } from 'keen-slider/react';
import { Link } from 'react-router-dom';
import 'keen-slider/keen-slider.min.css'; // Keen Slider styles

function TutorialSection({ className }) {
  const [tutorials, setTutorials] = useState([]);
  const [loading, setLoading] = useState(true);

  // Mock data for tutorials
  const mockData = [
    { id: 'login', title: '1.How to Login and fill the profile' },
    {
      id: 'keyword',
      title: '2.How to find the best fitted keyword for your expertise',
    },
    { id: 'positions', title: '3.How to find positions' },
    { id: 'supervisor', title: '4.How to find supervisor' },
    {
      id: 'generate',
      title:
        '5.How to generate Email correspondence, CV and SOP tailored for each professor research or position requirement',
    },
  ];

  useEffect(() => {
    const fetchTutorials = async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setTutorials(mockData); // Replace with actual API data
      } catch (error) {
        console.error('Error fetching tutorials:', error);
      }
      setLoading(false);
    };

    fetchTutorials();
  }, []);

  // Keen Slider configuration
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      breakpoints: {
        '(max-width: 1024px)': { slides: { perView: 2, spacing: 10 } },
        '(max-width: 768px)': { slides: { perView: 1, spacing: 0 } },
      },
      slides: { perView: 2, spacing: 10 },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => slider.next(), 4000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#358398',
        },
      }}
    >
      <section
        className={`appie-tutorial-area pt-140 mt-100 pb-180 ${
          className || ''
        }`}
        style={{ background: '#eef1f6' }}
      >
        <div className="container d-md-flex align-items-center justify-content-center">
          <div className="row col-md-12 pr-0 ">
            <div className="col-md-12 pr-0 ">
              <div
                className="appie-tutorial-title"
                style={{ paddingRight: 20 }}
              >
                <h2 className="text-center">
                  Our <span className="tutorials">Tutorials</span>
                </h2>
                <p className="text-center mb-20 mt-10">
                  Explore our latest tutorials and enhance your knowledge with
                  expert insights.
                </p>
              </div>
              {loading ? (
                <Skeleton active />
              ) : (
                <div className="slider-container">
                  <div ref={sliderRef} className="keen-slider">
                    {tutorials.map((tutorial) => (
                      <div
                        key={tutorial.id}
                        className="keen-slider__slide pt-10 "
                      >
                        <Card
                          title={tutorial.title}
                          hoverable
                          className="tutorial-card"
                          style={{
                            boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.05)',
                          }}
                        >
                          <div className="tutorial-card-content d-flex flex-column justify-content-between  h-100">
                            <Link
                              to={`/tutorial#${tutorial.id}`}
                              target="_blank"
                            >
                              <Button
                                variant="outlined"
                                icon={<i className="fas fa-play-circle"></i>}
                                className="play-button shadow-none w-100"
                                size="large"
                                color="primary"
                              >
                                Play
                              </Button>
                            </Link>
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}

export default TutorialSection;
