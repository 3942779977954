export const siteConfig = {
  links: {
    webapp: 'https://dashboard.applykite.com',
    telegram: 'https://t.me/ApplyKiteBot',
    twitter: 'https://x.com/ApplyKite',
    instagram: 'https://www.instagram.com/applykite/',
    linkedin: 'https://www.linkedin.com/company/applykite',
    email: 'support@applykite.com',
    telegramAdmin: 'https://t.me/ApplyKite',
  },

  pages: {
    positions: '/positions',
    supervisors: '/supervisors',
    blog: '/blogs',
    terms: '/terms-and-conditions',
    privacy: '/privacy-policy',
    contact: '/contact',
    aboutUs: '/about-us',
  },
};

export const endpoints = {
  admin: {
    blog: {
      getAll: '/api/posts', //
      getDetail: '/api/posts', //
      addComment: '/api/posts/posts', // /{post_id}/comment Method: POST //! NO TOKEN
      likeComment: '/api/posts/posts', // /{post_id}/like Method: POST //! NO TOKEN
    },
  },
};
