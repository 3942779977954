import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo_type.svg';
import { siteConfig } from '../../config/site';

function FooterHomeOne({ className }) {
  return (
    <>
      <section className={`appie-footer-area pb-4 ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="/">
                    <img src={logo} width={130} height={49} alt="kite-logo" />
                  </a>
                </div>
                <p>
                  Kite: the smart solution that company you on your post
                  graduate apply journey.
                </p>

                <div className="social mt-30">
                  <ul>
                    <li>
                      <a
                        href="https://t.me/ApplyKite"
                        aria-label="Telegram - ApplyKite"
                      >
                        <i className="fab fa-telegram-plane" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/shana_kite"
                        aria-label="Twitter - shana_kite"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/applykite/"
                        aria-label="Instagram  - ApplyKite"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/applykite"
                        aria-label="LinkedIn - ApplyKite"
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link to={siteConfig.pages.aboutUs}>About Us</Link>
                  </li>
                  <li>
                    <Link to={siteConfig.pages.positions}>Positions</Link>
                  </li>
                  <li>
                    <Link to={siteConfig.pages.supervisors}>Supervisors</Link>
                  </li>

                  <li>
                    <Link to={siteConfig.pages.blog}>Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  <li>
                    <Link to={siteConfig.pages.contact}>Contact</Link>
                  </li>
                  <li>
                    <a href="/#faq">Faqs</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">T&Cs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="mailto:support@applykite.com">
                      <i className="fal fa-envelope"></i>support@applykite.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i> +(44) 2034885486
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt"></i>
                      Wimbledon, London, United Kingdom
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between mt-4 pt-4">
                <div className="apps-download-btn"></div>
                <div className="copyright-text ">
                  <p style={{ fontSize: '12px' }}>
                    Copyright © 2021 Kite. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
