import React from 'react';
import thumb from '../../assets/images/webapp/2.webp';

function WebappSecond({ className }) {
  return (
    <section className={`appie-traffic-area pt-140 pb-180 ${className || ''}`}>
      <div className="container d-md-flex align-items-center">
        <div className="row col-md-4">
          <div className="">
            <div className="appie-traffic-title " style={{ paddingRight: 20 }}>
              <span>Generating Emails and CV</span>
              <h2 className="title">Email & CV</h2>
              <p className="pr-md-20">
                Effortlessly generate more efficient personalized email
                correspondence and CV based on professor research profile.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className="shadow-xl  overflow-hidden rounded-xl"
            style={{ padding: '0px 0 3px 3px', backgroundColor: '#fff' }}
          >
            <img
              loading="lazy"
              width={1100}
              height="100%"
              className="wow animated fadeInRight rounded-lg"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
              src={thumb}
              alt="generate cv and email based on posts"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebappSecond;
