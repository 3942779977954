import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/Helper/ScrollToTop';

// Lazy loading the components
import HomeOne from './components/HomeOne';
import Loader from './components/Helper/Loader';

const News = lazy(() => import('./components/News'));
const SingleNews = lazy(() => import('./components/News/SingleNews'));
const Positions = lazy(() => import('./components/PositionsService'));
const Supervisors = lazy(() => import('./components/SupervisorsService'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const Contact = lazy(() => import('./components/Contact'));
const Tutorial = lazy(() => import('./components/Tutorial'));
const Error = lazy(() => import('./components/Error'));
const Terms = lazy(() => import('./components/Terms'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
// const Webinar = lazy(() => import('./components/Webinar'));

function Routes() {
  return (
    <>
      <div className="appie-visible active">
        <Router>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" component={HomeOne} />
                <Route exact path="/blogs" component={News} />
                <Route exact path="/blogs/:slug" component={SingleNews} />
                <Route exact path="/positions" component={Positions} />
                <Route exact path="/supervisors" component={Supervisors} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/tutorial" component={Tutorial} />
                <Route exact path="/error" component={Error} />
                {/* <Route exact path="/webinar" component={Webinar} /> */}
                <Route exact path="/terms-and-conditions" component={Terms} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route component={Error} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
