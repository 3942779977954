import React, { useState } from 'react';

function FaqHomeOne({ className }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };
  return (
    <>
      <section className={`appie-faq-area pb-95 ${className || ''}`}>
        <div className="container">
          <div className="row" id="faq">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title pt-100">
                  Answer to some of your Questions
                </h3>
                {/* <h3 className="appie-title">Frequently asked questions</h3> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        {/* <h4>How do I get access to Kite platform?</h4> */}
                        <h4 className="pr-3">
                          Why is access to professors' social network
                          advertisements important?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            {/* Kite platfrom for graduates currently is only
                            accessible from Telegram Bot. We are working hard to
                            bring the solution up to the web. */}
                            Because it is often overlooked by many and therefore
                            greatly increases your chances of acceptance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(2)}
                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        {/* <h4>How often I get positions alerts?</h4> */}
                        <h4 className="pr-3">
                          Why is being informed early important?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 2 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            {/* Based on your desired research area you could get
                            alerts on a daily basis. */}
                            Because when you are among the first to apply, you
                            gain a competitive edge over others.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        {/* <h4>What make Kite to be a creative solution?</h4> */}
                        <h4 className="pr-4">
                          Why shouldn't you just rely on advertisements and
                          should communicate with professors?{' '}
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            {/* Giving you access to all announced opportunities in
                            the social media and websites is just a shortcut in
                            your time consuming journey toward your Apply goals. */}
                            Because when a professor is impressed by your
                            resume, even if they don't have an open position,
                            they can find various opportunities for you such as
                            scholarships and research grants.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(4)}
                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        {/* <h4>Who can benefit from Kite?</h4> */}
                        <h4 className="pr-3">
                          Why is it essential to craft tailored email
                          correspondence along with a relevant CV and SOP for
                          each supervisor?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 4 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>
                            {/* If you are a postgraduate student who is starting
                            their Apply journey, Kite is the number one helper
                            for you. */}
                            Email is your most powerful tool for presenting
                            yourself and capturing a professor's interest with
                            your skills. Customizing your CV and SOP for each
                            supervisor and their specific position requirements
                            can greatly enhance your chances of standing out.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Can't find an answer or need more information?{' '}
                  <a href="mailto:support@applykite.com">Email</a>
                  &nbsp;or&nbsp;
                  <a href="https://t.me/ApplyKite"> Chat</a>
                  &nbsp;to us
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeOne;
