/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Skeleton } from 'antd';
import axios from 'axios';
import BlogCard from '../News/BlogCard/BlogCard';
import '../News/blog.css';

const apiUrl = process.env.REACT_APP_API_URL;

const SkeletonCard = () => (
  <Card hoverable cover={<Skeleton.Image className="skeleton-card-image" />}>
    <Skeleton active />
  </Card>
);

function BlogHomeOne({ className }) {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogs = async (pageParam) => {
    setLoading(true);
    try {
      const url = `${apiUrl}/api/posts`;

      const response = await axios.get(url, {
        params: {
          page: pageParam - 1,
          page_size: 3,
        },
      });
      setBlogs(response.data.posts);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs(1);
  }, []);

  if (blogs.length === 0) {
    return null;
  }

  return (
    <>
      <section className={`appie-blog-area pt-90 pb-95 ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Latest blog posts</h3>
                <p>Different layouts and styles for team sections.</p>
              </div>
            </div>
          </div>
          <div className="">
            {loading ? (
              <Row gutter={[16, 16]}>
                {[...Array(3)].map((_, index) => (
                  <Col xs={24} sm={12} lg={8} key={index}>
                    <SkeletonCard />
                  </Col>
                ))}
              </Row>
            ) : (
              <>
                <Row gutter={[16, 16]}>
                  {blogs.map((blog) => (
                    <Col xs={24} sm={12} lg={8} key={blog.id}>
                      <BlogCard blog={blog} />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogHomeOne;
